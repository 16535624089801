import { DESCRIPTION_LENGTH } from "../constants/ProductConstants";
import {
  REQUEST_STATUS_CANCELED,
  REQUEST_STATUS_CONFIRMED,
  REQUEST_STATUS_DECLINED,
  REQUEST_STATUS_DELETED,
  REQUEST_STATUS_OPEN,
  REQUEST_STATUS_OPTION,
  REQUEST_STATUS_SENT,
} from "../constants/RequestConstants";
import i18n from "i18next";

export const formatPrice = function (value) {
  return value.toLocaleString("nl-NL", {
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  });
};

export const formatWeight = function (value) {
  value = parseInt(value);
  return value.toLocaleString("nl-NL", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const formatNameToURL = (name) => {
  if (!name) return null;
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // remove invalid chars
  // collapse whitespace and replace by -
  // collapse dashes
  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
};

export const formatRequestStatus = (status) => {
  if (status === REQUEST_STATUS_SENT) return i18n.t("sent");
  else if (status === REQUEST_STATUS_OPTION) return i18n.t("option");
  else if (status === REQUEST_STATUS_CONFIRMED) return i18n.t("confirmed");
  else if (status === REQUEST_STATUS_DELETED) return i18n.t("deleted");
  else if (status === REQUEST_STATUS_DECLINED) return i18n.t("declined");
  else if (status === REQUEST_STATUS_CANCELED) return i18n.t("canceled");
  else return i18n.t("sent");
};

export const getStatusColor = (status) => {
  if (status === REQUEST_STATUS_SENT || status === REQUEST_STATUS_OPEN)
    return "blue";
  else if (status === REQUEST_STATUS_OPTION) return "orange";
  else if (status === REQUEST_STATUS_CONFIRMED) return "green";
  else if (status === REQUEST_STATUS_DELETED) return "red";
  else if (status === REQUEST_STATUS_DECLINED) return "red";
  else if (status === REQUEST_STATUS_CANCELED) return "yellow";
  else return "orange";
};
