import React from "react";
import { Redirect, Switch } from "react-router-dom";
import DashboardRoutes from "./DashboardRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { active_requests } from "../urls";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={active_requests} />
      <React.Fragment>
        {/* Route for login */}
        <AuthenticationRoutes />

        {/* Routes for dashboard layouts */}
        <DashboardRoutes />
      </React.Fragment>
    </Switch>
  );
};

export default Routes;
