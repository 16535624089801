import {
  SAVE_EQUIPMENT,
  DELETE_ALL_EQUIPMENT,
} from "../actions/equipmentActions";

const initState = null;

const equipmentReducer = (state = initState, action) => {
  const currentEquipment = state,
    newEquipment = action.payload;

  if (action.type === SAVE_EQUIPMENT) {
    return newEquipment;
  }

  if (action.type === DELETE_ALL_EQUIPMENT) {
    return initState;
  }

  return state;
};

export default equipmentReducer;
