import { transparentize } from "./helperFn";

export const colors = {
  primary: "#1C9E49",
  primary80: transparentize("#1C9E49", 0.8),
  secondary: "#1E1E1E",
  text: "#2D3748",
  header: "#2D3748",
  btn: "#4B5A57",
  cta_btn: "#1C9E49",
  cta_btn_text: "#fff",
  standout_text: "#579b3c",
  added_to_cart_btn: "#009d00",
  category_header: "#e5e5e5",
  clickable_text: "#0066ff",
  hamburger: "#1C9E49",
  menu_items: "#222",
  background: "#F4F6FA",
};

export const styles = {
  btn_border_radius: "0px",
  card_border_radius: "10px",
  bullet_point_icon: "💎  ",
  card_shadow: "0 0 6px 2px rgba(0 0 0 / 8%)",
  card_shadow_active: "0 0 6px 2px rgba(0 0 0 / 20%)",
};
