import React, { useEffect, useState } from "react";
import {
  Avatar,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Grid,
  Layout,
  Menu,
} from "antd";
import {
  ArrowLeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled, { ThemeProvider } from "styled-components";
import { active_requests, finished_requests, login, materials } from "../urls";
import { Link, useHistory } from "react-router-dom";
import { colors } from "../utils/theme";
import { useTranslation } from "react-i18next";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  FIRESTORE_COMPANIES_TABLE,
  FIRESTORE_EQUIPMENT_TABLE,
  FIRESTORE_REQUESTS_TABLE,
  FIRESTORE_USERS_TABLE,
} from "../constants/FirebaseConstants";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase";
import { Loader } from "../components/loader/Loader";
import i18n from "i18next";
import { device, getBreakPoint } from "../utils/breakpoints";
import _ from "lodash";
import { getAllEquipment } from "../helpers/api/rentman";
import {
  deleteAllEquipment,
  saveEquipment,
} from "../redux/actions/equipmentActions";
import nlNL from "antd/locale/nl_NL";
import { getFirebaseIDToken } from "../helpers/firebase";
import CartDrawer from "../components/request/CartDrawer";
import { addToCart, deleteFromCart } from "../redux/actions/cartActions";
import { isAlwaysIncluded } from "../helpers/product";

const { Header, Content, Sider, Footer } = Layout;

const menuItems = [
  {
    key: `materials`,
    label: i18n.t("new_request"),
    path: materials,
  },
  {
    key: `active-requests`,
    label: i18n.t("active_requests"),
    path: active_requests,
  },
  {
    key: `finished-requests`,
    label: i18n.t("finished_requests"),
    path: finished_requests,
  },
];

const { useBreakpoint } = Grid;

const DashboardLayout = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const cartData = useSelector((state) => state.cartData);
  const companies = useSelector(
    (state) => state.firestoreReducer.ordered.companies,
  );
  const company = !!companies ? companies[0] : null;
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [equipmentRetrieved, setEquipmentRetrieved] = useState(false);
  const cartItemsAmount = _.sumBy(cartData, "quantity");
  const [cartDrawerVisible, setCartDrawerVisible] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const profileEmpty = !!profile && profile.isLoaded && profile.isEmpty;
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");

  const companyTheme = {
    primary: !!company ? company.primaryColor : colors.primary,
  };

  const queries = [];
  if (!!profile && !profile.isEmpty && !!profile.companyId) {
    queries.push(
      {
        collection: FIRESTORE_COMPANIES_TABLE,
        doc: profile.companyId,
      },
      {
        collection: FIRESTORE_REQUESTS_TABLE,
        where: [
          ["companyId", "==", profile.companyId],
          ["savedToRentman", "==", true],
        ],
      },
      {
        collection: FIRESTORE_EQUIPMENT_TABLE,
        where: [
          [
            "rentmanMaterialCode",
            ">=",
            !!company ? company.rentmanMaterialCode : null,
          ],
          [
            "rentmanMaterialCode",
            "<=",
            !!company ? company.rentmanMaterialCode + "\uf8ff" : null,
          ],
        ],
      },
      {
        collection: FIRESTORE_USERS_TABLE,
        where: ["companyId", "==", profile.companyId],
        storeAs: "companyUsers",
      },
    );
  }

  useFirestoreConnect(queries);

  useEffect(() => {
    if (profileEmpty) history.push(login);
    if (!!company && !!company.logo) setIsLoading(false);
    if (!!company && !equipmentRetrieved && !!company.rentmanMaterialCode) {
      dispatch(deleteAllEquipment());
      getFirebaseIDToken()
        .then((token) => {
          getAllEquipment(company.rentmanMaterialCode, token)
            .then((data) => {
              if (!!data) {
                dispatch(saveEquipment(data));
                handleSetDefaultCartItems(data);
              }
              setEquipmentRetrieved(true);
            })
            .catch((err) => {
              console.log(err);
              dispatch(saveEquipment([]));
              setEquipmentRetrieved(true);
            });
        })
        .catch((err) => console.log(err));
    }
  }, [company, profileEmpty]);

  const handleSetDefaultCartItems = (equipment) => {
    equipment.forEach((item) => {
      if (isAlwaysIncluded(item, company.rentmanMaterialCode)) {
        if (!_.find(cartData, (cartItem) => cartItem.id === item.id)) {
          item.alwaysIncluded = true;
          dispatch(addToCart(item, 1));
        }
      }
    });

    // Remove cart items that are not in equipment anymore
    cartData.forEach((cartItem) => {
      if (!_.find(equipment, (item) => item.id === cartItem.id)) {
        dispatch(deleteFromCart(cartItem));
      }
    });
  };

  const toggleCollapsed = () => {
    if (isMobile) setMobileMenuOpen(true);
    else setCollapsed(!collapsed);
  };

  const onClickMenuItem = (e) => {
    history.push(e.item.props.path);
    setMobileMenuOpen(false);
  };

  const handleClickCart = () => {
    setCartDrawerVisible(true);
  };

  const onCloseMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  if (isLoading) return <Loader />;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: !!company ? company.primaryColor : colors.primary,
        },
      }}
      locale={nlNL}
    >
      <ThemeProvider theme={companyTheme}>
        <Layout>
          <Header
            className="header"
            style={{
              background: "#FFF",
              paddingInlineStart: 0,
              paddingInlineEnd: 16,
              borderBottom: `1px solid ${colors.background}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="d-flex align-items-center">
              <Logo onClick={() => history.push(active_requests)}>
                <img src={company.logo} alt="logo" />
              </Logo>
              {isMobile ? (
                <MenuUnfoldOutlined
                  onClick={toggleCollapsed}
                  style={{ fontSize: 18, marginLeft: 12 }}
                />
              ) : collapsed ? (
                <MenuUnfoldOutlined
                  onClick={toggleCollapsed}
                  style={{ fontSize: 18, marginLeft: 8 }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={toggleCollapsed}
                  style={{ fontSize: 18, marginLeft: 8 }}
                />
              )}
            </div>
            <div className="d-flex align-items-center">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={() => firebase.logout()}>
                      {t("logout")}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Avatar
                  size={"large"}
                  style={{ marginLeft: 16, marginRight: 16 }}
                >
                  {`${profile?.firstName?.slice(0, 1)}${profile?.lastName?.slice(0, 1)}`}
                </Avatar>
              </Dropdown>
              <Link
                to={"#"}
                onClick={handleClickCart}
                style={{ position: "relative" }}
              >
                <CartIcon />
                <Counter>
                  <span>{cartItemsAmount}</span>
                </Counter>
              </Link>
            </div>
          </Header>
          <Layout>
            {isMobile ? (
              <Drawer
                placement="left"
                closable={false}
                onClose={onCloseMobileMenu}
                visible={mobileMenuOpen}
                bodyStyle={{ padding: 5, paddingTop: 12 }}
              >
                <div className="h-100">
                  <div
                    className={
                      "d-flex justify-content-between align-items-center"
                    }
                  >
                    <MobileLogo>
                      <img src={company.logo} alt="logo" />
                    </MobileLogo>
                    <div className="nav-close mr-2" onClick={onCloseMobileMenu}>
                      <ArrowLeftOutlined style={{ fontSize: 20 }} />
                    </div>
                  </div>
                  <Divider />
                  <div className="mobile-nav-menu">
                    <Menu
                      mode="inline"
                      defaultSelectedKeys={[
                        _.find(
                          menuItems,
                          (item) => item.path === window.location.pathname,
                        ).key,
                      ]}
                      style={{
                        paddingTop: "1rem",
                        height: "100%",
                        borderRight: `1px solid ${colors.background}`,
                      }}
                      items={menuItems}
                      onClick={onClickMenuItem}
                    />
                  </div>
                </div>
              </Drawer>
            ) : (
              <Sider
                width={collapsed ? 0 : 256}
                style={{
                  minHeight: "100vh",
                  background: "#FFF",
                }}
                collapsed={collapsed}
              >
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[
                    _.find(
                      menuItems,
                      (item) => item.path === window.location.pathname,
                    ).key,
                  ]}
                  style={{
                    paddingTop: "1rem",
                    height: "100%",
                    borderRight: `1px solid ${colors.background}`,
                  }}
                  items={menuItems}
                  onClick={onClickMenuItem}
                />
              </Sider>
            )}
            <Layout>
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                  background: colors.background,
                }}
              >
                {children}
              </Content>
            </Layout>
          </Layout>
          <Footer style={{ background: colors.secondary }}>
            <FooterLogo>
              <img src="/assets/img/logo/logo.png" alt="logo showdesign" />
            </FooterLogo>
            <FooterLinks>
              <a
                href={"https://showdesign.nl/docs/algemene-voorwaarden.pdf"}
                target={"_blank"}
              >
                {t("terms_and_conditions")}
              </a>
            </FooterLinks>
          </Footer>
        </Layout>
        <CartDrawer
          visible={cartDrawerVisible}
          closeDrawer={() => setCartDrawerVisible(false)}
        />
      </ThemeProvider>
    </ConfigProvider>
  );
};

const Counter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  top: -10px;
  right: -9px;
  width: 20px;
  height: 20px;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background-color: ${colors.primary};

  span {
    align-self: center;
  }
`;

const CartIcon = styled.div`
  width: 30px;
  height: 34px;
  background-image: url("/assets/img/button-icons/cart3.svg");
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease;

  @media ${device.lg} {
    width: 32px;
    height: 37px;
  }
`;

const FooterLinks = styled.div`
  display: inline-grid;
  justify-content: space-around;
  margin-top: 6px;

  @media ${device.maxMd} {
    margin-top: 12px;
  }

  a {
    font-size: 16px;
    color: #d7d7d7;
  }

  @media ${device.lg} {
    display: flex;

    a {
      display: block;
    }
  }
`;

const FooterLogo = styled.div`
  float: left;
  width: 180px;
  margin-left: 25px;
  margin-right: 25px;

  img {
    width: 100%;
  }
`;

const MobileLogo = styled.div`
  float: left;
  width: 200px;
  height: 60px;
  margin-left: 25px;
  margin-right: 25px;
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 52px;
  }
`;

const Logo = styled.div`
  display: none;
  float: left;
  width: 200px;
  height: 60px;
  margin-left: 25px;
  margin-right: 25px;
  cursor: pointer;

  @media ${device.lg} {
    display: block;
  }

  img {
    max-width: 100%;
    max-height: 52px;
  }
`;

export default DashboardLayout;
