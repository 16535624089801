import React, { Fragment } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { colors } from "../utils/theme";
import { device } from "../utils/breakpoints";

const AuthLayout = ({ children }) => {
  return (
    <Fragment>
      <StyledRow>
        <ImageCol lg={15} />
        <Col xs={24} lg={9}>
          <FormWrapper>
            <div style={{ maxWidth: 470, alignSelf: "center" }}>{children}</div>
          </FormWrapper>
        </Col>
      </StyledRow>
    </Fragment>
  );
};
const FormWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 2rem;

  h2 {
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    color: ${colors.header};

    @media ${device.lg} {
      font-size: 28px;
      line-height: 32px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: ${colors.header};

    @media ${device.lg} {
      font-size: 26px;
      line-height: 30px;
    }
  }

  p {
    text-align: center;
    margin-bottom: 1rem;
    color: ${colors.text};

    @media ${device.lg} {
      font-size: 18px;
      margin-bottom: 2rem;
    }
  }
`;

const ImageCol = styled(Col)`
  background-image: url("/assets/img/backgrounds/showdesign-outside.png");
  background-size: cover;
`;

const StyledRow = styled(Row)`
  min-height: 100vh;
`;

export default AuthLayout;
