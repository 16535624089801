import { formatNameToURL, formatWeight } from "./formatters";
import _ from "lodash";
import i18n from "../translations/i18n";

// get product discount price with default
export const getDiscountPriceWithDefault = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : price;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product) => {
  let productInCart = cartItems.filter((single) => single.id === product.id)[0];
  if (cartItems.length >= 1 && productInCart) {
    return cartItems.filter((single) => product.id === single.id)[0].quantity;
  } else {
    return 0;
  }
};

/**
 * Filter cart items depending on the product. Used in cart reducer.
 * @param cartItems
 * @param product
 * @returns {*}
 */
export const filterProduct = (cartItems, product) => {
  if (product.variation && !product.type) {
    return cartItems.filter(
      (item) =>
        item.id === product.id &&
        item.variation &&
        product.variation.weight &&
        item.variation.weight === product.variation.weight,
    )[0];
  } else if (product.type && !product.variation) {
    return cartItems.filter(
      (item) =>
        item.id === product.id && !!item.type && item.type === product.type,
    )[0];
  } else if (product.variation && product.type) {
    return cartItems.filter(
      (item) =>
        item.id === product.id &&
        item.variation &&
        item.type &&
        item.variation.weight === product.variation.weight &&
        item.type === product.type,
    )[0];
  } else {
    return cartItems.filter((item) => item.id === product.id)[0];
  }
};

/**
 * Find the puchased product variation from cms product
 * @param cmsProduct
 * @param orderProduct
 * @returns {*}
 */
export const findProductVariation = (cmsProduct, orderProduct) => {
  if (!!orderProduct.variation && orderProduct.variation.weight) {
    return cmsProduct.variations.filter(
      (variation) => variation.weight === orderProduct.variation.weight,
    )[0];
  } else if (cmsProduct.variations) {
    return cmsProduct.variations[0];
  }
};

/**
 * Find the puchased product type from cms product
 * @param cmsProduct
 * @param orderProduct
 * @returns {*}
 */
export const findProductType = (cmsProduct, orderProduct) => {
  if (!!orderProduct.type && !!cmsProduct.type && orderProduct.type) {
    return cmsProduct.type.filter((item) => item === orderProduct.type)[0];
  } else if (cmsProduct.type) {
    return cmsProduct.type[0];
  }
};

export const isAlwaysIncluded = (item, rentmanMaterialCode) => {
  const regex = /[a-z]/i;
  const uniquePart = item.code.split(rentmanMaterialCode)[1];
  if (!uniquePart) return false;
  const slug = uniquePart.split(")").pop(); // The category of some items stands in between (). Get only the part after this
  return regex.test(slug) && !uniquePart.includes("PERSONEEL");
};

export const isHourlyRate = (item) => {
  return item.code.includes("PERSONEEL");
};

export const getHourlyRateItem = (items) => {
  if (!!items) {
    return items.find((item) => isHourlyRate(item));
  } else return null;
};
