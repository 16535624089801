import firebase from "firebase";
import { CURRENCY_CODE } from "../constants/DeliveryConstants";
import { getDiscountPriceWithDefault } from "./product";

/**
 * Dynamic analytics event for 1 specific item
 **/
export const setProductAnalyticsEvent = (eventName, product, quantity) => {
  const value = getDiscountPriceWithDefault(product.price, product.discount);
  firebase.analytics().logEvent(eventName, {
    currency: CURRENCY_CODE,
    value: !!quantity ? value * quantity : value,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        currency: CURRENCY_CODE,
        discount:
          product.price -
          getDiscountPriceWithDefault(product.price, product.discount),
        index: 0,
        price: product.price,
        quantity: !!quantity ? quantity : 1,
      },
    ],
  });
};
