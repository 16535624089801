export const generateOrderId = () => {
  const generateSegment = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // Generate each segment of the ID
  const segment1 = generateSegment(4);
  const segment2 = generateSegment(6);
  const segment3 = generateSegment(4);

  // Combine the segments with dashes
  return `${segment1}-${segment2}-${segment3}`;
};
