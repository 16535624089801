import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./translations/i18n";
import { Loader } from "./components/loader/Loader";
import CookieBanner from "./components/banner/CookieBanner";
import Routes from "./routes";
import "dayjs/locale/nl";
import dayjs from "dayjs";

dayjs.locale("nl");

const App = () => {
  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes />
        </Suspense>
        <CookieBanner />
      </Router>
    </>
  );
};

export default App;
