export const SAVE_EQUIPMENT = "SAVE_EQUIPMENT";
export const DELETE_ALL_EQUIPMENT = "DELETE_ALL_EQUIPMENT";

export const saveEquipment = (equipment) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_EQUIPMENT,
      payload: equipment,
    });
  };
};

export const deleteAllEquipment = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_ALL_EQUIPMENT,
    });
  };
};
