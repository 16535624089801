import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { email_verified, forgot_password, login, register } from "../urls";
import AuthLayout from "../layouts/AuthLayout";
import NavMotion from "../layouts/NavMotion";

const AuthLogin = lazy(() => import("../pages/other/Login"));
const AuthRegister = lazy(() => import("../pages/other/Register"));

const AuthenticationRoutes = () => {
  const location = useLocation();

  return (
    <Route path={[login, register, forgot_password, email_verified]}>
      <AuthLayout>
        <Switch location={location} key={location.pathname}>
          <NavMotion>
            <Route path={login} component={AuthLogin} />
            <Route path={register} component={AuthRegister} />
          </NavMotion>
        </Switch>
      </AuthLayout>
    </Route>
  );
};

export default AuthenticationRoutes;
