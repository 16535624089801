let paymentCheckout =
  process.env.REACT_APP_API_URL + "/api/payment/stripe/checkout";
let mailService = process.env.REACT_APP_API_URL + "/api/email";
let authService = process.env.REACT_APP_API_URL + "/api/auth";
let rentmanService = process.env.REACT_APP_API_URL + "/api/rentman";
module.exports = {
  home: "/",
  dashboard: "/dashboard",
  about: "/over",
  contact: "/contact",
  my_account: "/mijn-account",
  my_orders: "/mijn-bestellingen",
  login_register: "/inloggen-registreren",
  login: "/inloggen",
  login_email_link: "/inloggen/:emailLink",
  register: "/registreren",
  forgot_password: "/reset-wachtwoord",
  cart: "/winkelwagen",
  wishlist: "/favorieten",
  compare: "/vergelijken",
  checkout: "/bestellen",
  document_id_param: ":documentId",
  order_processing: "/bestelling/verwerken/",
  order_confirmed: "/bestelling/bevestigd",
  order_failed: "/bestelling/mislukt",
  not_found: "/niet-gevonden",
  blog: "/blog",
  shopLandingPage: "/shop",
  materials: "/materialen",
  productsByCategory: "/producten/:category",
  product: "/product/",
  productDetail: "/product/:url",
  categorySlug: "/categorie/",
  categoryLandingPage: "/categorie/:category",
  privacy: "/privacy",
  ideal_checkout: paymentCheckout + "/ideal",
  credit_card_checkout: paymentCheckout + "/creditcard",
  ban_contact_checkout: paymentCheckout + "/bancontact",
  order_confirmed_email: mailService + "/order/confirmed",
  order_processing_redirect_success: "?redirect_status=succeeded",
  order_processing_redirect_failed: "?redirect_status=failed",
  contact_email: mailService + "/contact",
  order_invoice: mailService + "/order/invoice",
  welcome_email: mailService + "/signup/welcome",
  verify_email: mailService + "/signup/verify",
  email_verified: "/email/verificatie/:encodedEmail",
  email_verified_link: "/email/verificatie/",
  verify_user_email: authService + "/verify/",
  delivery_returns: "/verzenden-retourneren",
  faq: "/veelgestelde-vragen",
  active_requests: "/lopende-aanvragen",
  finished_requests: "/voltooide-aanvragen",
  rentman_equipment: rentmanService + "/equipment/",
  rentman_contacts: rentmanService + "/contacts/",
  rentman_project_request: rentmanService + "/project_request",
  rentman_updated_projects: rentmanService + "/projects/updated",
  login_link_email: mailService + "/login",
  request_changed_email: mailService + "/request_changed",
};
