import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { privacy } from "../../urls";
import { device } from "../../utils/breakpoints";
import { colors } from "../../utils/theme";
import { transparentize } from "../../utils/helperFn";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const CookieBanner = () => {
  const { t } = useTranslation();
  const [cookieValue, setCookieValue] = useState(Cookies.get("allow-cookies"));

  const onPressAccept = () => {
    setCookieValue(true);
    Cookies.set("allow-cookies", true);
  };

  return (
    <CookieBannerWrapper className={cookieValue ? "d-none" : ""}>
      <Banner>
        <CookieIcon />
        <span>{t("cookieBar.cookieNotice")}</span>
        <ButtonsWrapper>
          <StyledLink to={privacy}>{t("cookieBar.readMoreBtn")}</StyledLink>
          <StyledButton onClick={onPressAccept}>
            {t("cookieBar.accept")}
          </StyledButton>
        </ButtonsWrapper>
      </Banner>
    </CookieBannerWrapper>
  );
};

const CookieIcon = styled.div`
  &:before {
    content: "🍪";
    font-size: 20px;
    @media ${device.md} {
      font-size: 30px;
    }
  }
`;

const StyledButton = styled(Button)`
  border-style: none;
  width: 100%;
  font-size: 12px;
  background: ${colors.cta_btn};
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  margin: 5px 0;
  height: 40px;
  
  span {
    color: ${colors.cta_btn_text} !important;
  }

  &:hover,
  &:focus {
    background: ${transparentize(colors.cta_btn, 0.8)} !important;
    color: ${colors.cta_btn_text};
  }
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  height: auto;
  color: rgb(181, 181, 181);
  padding: 10px 18px;
  border: none;
  box-shadow: none;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.5s ease 0s;

  &:focus,
  &:active {
    color: ${colors.primary};
  }

  @media ${device.md} {
    font-size: 16px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

const Banner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  opacity: 1;
  box-shadow: rgb(0 0 0 / 10%) 0px 24px 32px, rgb(0 0 0 / 10%) 0px 8px 32px;
  margin: 0 6px 48px;
  padding: 16px;
  border-radius: 32px;
  background: #3a3a3a;

  @media ${device.lg} {
    margin: 0 auto 48px;
  }

  span {
    color: #fff;
    font-size: 12px;
    margin: 0 20px;

    @media ${device.md} {
      font-size: 16px;
    }
  }
`;

const CookieBannerWrapper = styled.div`
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 1201;
`;

export default CookieBanner;
