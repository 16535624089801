import cartReducer from "./cartReducer";
import filterReducer from "./filterReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import equipmentReducer from "./equipmentReducer";

const rootReducer = combineReducers({
  firebaseReducer,
  firestoreReducer,
  cartData: cartReducer,
  filterData: filterReducer,
  equipmentData: equipmentReducer,
});

export default rootReducer;
