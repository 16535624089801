export const breakpoints = {
  sm: 576, //Small screen start
  md: 768, //Medium screen start
  lg: 992, //Large screen start
  xl: 1200, //Extra Large screen start
  xxl: 1440, //Extra Large screen start
};

export const device = {
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  maxSm: `(max-width: ${breakpoints.sm}px)`,
  maxMd: `(max-width: ${breakpoints.md}px)`,
  maxLg: `(max-width: ${breakpoints.lg}px)`,
  maxXl: `(max-width: ${breakpoints.xl}px)`,
  maxXxl: `(max-width: ${breakpoints.xxl}px)`,
};

/**
 * Get Breakpoint
 * @param {Object} screens - Grid.useBreakpoint() from antd
 * @return {Array} array of breakpoint size
 */
export const getBreakPoint = (screens) => {
  let breakpoints = [];
  for (const key in screens) {
    if (screens.hasOwnProperty(key)) {
      const element = screens[key];
      if (element) {
        breakpoints.push(key);
      }
    }
  }
  return breakpoints;
};
