import axios from "axios";
import {
  rentman_contacts,
  rentman_equipment,
  rentman_project_request,
  rentman_updated_projects,
} from "../../urls";

export const getAllEquipment = (clientCode, token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: token },
    };
    axios
      .get(rentman_equipment + clientCode, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveRequestToRentman = (object, token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: token },
    };
    axios
      .post(rentman_project_request, object, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyContactsFromRentman = (companyId, token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: token },
    };
    axios
      .get(rentman_contacts + companyId, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUpdatedRequests = (requests, token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: token },
    };
    axios
      .post(rentman_updated_projects, requests, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
