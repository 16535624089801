import styled from "styled-components";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Select,
} from "antd";
import { formatPrice } from "../../helpers/formatters";
import {
  REQUEST_ID_PREFIX,
  REQUEST_STATUS_SENT,
} from "../../constants/RequestConstants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  addToCart,
  decreaseQuantity,
  deleteAllFromCart,
  deleteAlwaysIncludedItemsFromCart,
  deleteFromCart,
  updateProductComment,
} from "../../redux/actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "../../helpers/browser";
import { CURRENCY } from "../../constants/DeliveryConstants";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { setProductAnalyticsEvent } from "../../helpers/analytics";
import {
  ANALYTICS_ADD_TO_CART,
  ANALYTICS_REMOVE_FROM_CART,
} from "../../constants/AnalyticsConstants";
import { generateOrderId } from "../../services/firestore/orders";
import _ from "lodash";
import { useFirestore } from "react-redux-firebase";
import { getHourlyRateItem, isAlwaysIncluded } from "../../helpers/product";
import { saveRequestToRentman } from "../../helpers/api/rentman";
import { getFirebaseIDToken } from "../../helpers/firebase";
import { FIRESTORE_REQUESTS_TABLE } from "../../constants/FirebaseConstants";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CartDrawer = ({ visible, closeDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const equipment = useSelector((state) => state.equipmentData);
  const cartData = useSelector((state) => state.cartData);
  const cartItemsTotal = _.sumBy(
    cartData,
    (item) => item.quantity * item.price,
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  // Purely to set the right value in the input fields
  const [materialNotes, setMaterialNotes] = useState([]);
  const [materialNotesAdded, setMaterialNotesAdded] = useState(false);
  const companies = useSelector(
    (state) => state.firestoreReducer.ordered.companies,
  );
  const [requestForm] = Form.useForm();
  const company = !!companies ? companies[0] : null;

  useEffect(() => {
    if (!materialNotesAdded) {
      setMaterialNotes(
        cartData.map((item) => {
          if (item.external_remark) return item.external_remark;
          else return null;
        }),
      );
      setMaterialNotesAdded(true);
    }

    const cartIncludesEntertainment = !!cartData.find((item) =>
      item.tags?.includes("entertainment"),
    );
    const cartIncludesAlwaysIncluded = !!cartData.find((item) =>
      isAlwaysIncluded(item, company.rentmanMaterialCode),
    );

    if (
      (cartData.length === 0 && !!equipment && !cartIncludesEntertainment) ||
      (!cartIncludesAlwaysIncluded && !cartIncludesEntertainment)
    )
      handleSetDefaultCartItems(equipment);

    if (
      cartData.length > 0 &&
      !!equipment &&
      cartIncludesEntertainment &&
      cartIncludesAlwaysIncluded
    ) {
      // Remove all always includes items from cart
      dispatch(deleteAlwaysIncludedItemsFromCart());
    }
  }, [cartData]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product, 1));
    setProductAnalyticsEvent(ANALYTICS_ADD_TO_CART, product, 1);
  };

  const handleDecreaseCartQuantity = (product, index) => {
    if (product.quantity === 1) handleRemoveMaterialNotes(index);
    dispatch(decreaseQuantity({ ...product, quantity: product.quantity - 1 }));
    setProductAnalyticsEvent(ANALYTICS_REMOVE_FROM_CART, product, 1);
  };

  const handleRemoveItemFromCart = (product, index) => {
    handleRemoveMaterialNotes(index);
    dispatch(deleteFromCart(product));
    setProductAnalyticsEvent(
      ANALYTICS_REMOVE_FROM_CART,
      product,
      product.quantity,
    );
  };

  const onClickProceed = () => {
    if (currentStep === 0) setCurrentStep(1);
    else if (currentStep === 1) {
      requestForm
        .validateFields()
        .then((values) => {
          setSubmitLoading(true);
          const start = dayjs(values.date[0].second(0)).unix();
          const end = dayjs(values.date[1].second(0)).unix();
          const fullName = profile.firstName + " " + profile.lastName;
          values.contactPerson = {
            email: auth.email,
            name: fullName,
          };
          values.contactPersonName = fullName;
          values.space = values.space || null;
          let techSupportHours =
            dayjs.unix(end).diff(dayjs.unix(start), "minutes") / 60;
          techSupportHours =
            Math.round((techSupportHours + Number.EPSILON) * 100) / 100;
          if (techSupportHours < 3) techSupportHours = 3;
          delete values.date;
          values.notes = values.notes || null;
          const object = {
            ...values,
            userId: auth.uid,
            companyId: profile.companyId,
            equipment: cartData,
            savedToRentman: false,
            totalPrice: cartItemsTotal,
            requestId: REQUEST_ID_PREFIX + generateOrderId(dayjs().unix()),
            status: REQUEST_STATUS_SENT,
            createdAt: dayjs().unix(),
            rentmanCompanyId: company.rentmanId,
            email: auth.email,
            location: company.company,
            companyName: company.company,
            techSupportHours: techSupportHours,
            start: start,
            end: end,
          };
          // Calculate personnel costs
          if (values.techSupportNeeded) {
            // Set hourly rate
            const hourlyRate = getHourlyRateItem(
              equipment,
              company.rentmanMaterialCode,
            );
            if (!!hourlyRate) {
              // Clone cart in order to add hourly rate to it
              const clonedCart = _.cloneDeep(cartData);
              const personAmount = 1;
              hourlyRate.quantity = personAmount * techSupportHours;
              object.personnelHours = personAmount * techSupportHours;
              object.personnelCosts =
                personAmount * techSupportHours * hourlyRate.price;
              clonedCart.push(hourlyRate);
              object.equipment = clonedCart;
            } else object.personnelCosts = null;
          } else object.personnelCosts = null;

          object.techSupportPersons = null; // Has been replaced by techSupportNeeded (boolean)
          // Save request to firestore
          firestore
            .collection(FIRESTORE_REQUESTS_TABLE)
            .add(object)
            .then((result) => {
              object.documentId = result.id;
              object.date = dayjs(object.date, "DD-MM-YYYY").format(
                "YYYY-MM-DD",
              );
              object.startTime = dayjs(object.startTime, "HH:mm")
                .subtract(2, "hours")
                .format("HH:mm");
              object.endTime = dayjs(object.endTime, "HH:mm")
                .subtract(2, "hours")
                .format("HH:mm");
              // Save request to Rentman
              getFirebaseIDToken()
                .then((token) => {
                  saveRequestToRentman(object, token)
                    .then(() => {
                      closeDrawer();
                      requestForm.resetFields();
                      setMaterialNotes([]);
                      notification.success({
                        message: t("notifications.request_submitted"),
                      });
                      setSubmitLoading(false);
                      setCurrentStep(0);
                      dispatch(deleteAllFromCart());
                    })
                    .catch((err) => {
                      console.log(err);
                      message.error(err.message);
                      setSubmitLoading(false);
                    });
                })
                .catch((err) => {
                  console.log(err);
                  message.error(err.message);
                  setSubmitLoading(false);
                });
            });
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSetDefaultCartItems = (equipment) => {
    if (!!equipment)
      equipment.forEach((item) => {
        if (isAlwaysIncluded(item, company.rentmanMaterialCode)) {
          if (!_.find(cartData, (cartItem) => cartItem.id === item.id)) {
            item.alwaysIncluded = true;
            dispatch(addToCart(item, 1));
          }
        }
      });
  };

  const handleCommentChange = (value, index) => {
    dispatch(updateProductComment(cartData[index], value));
  };

  const handleSetMaterialNotes = (value, index) => {
    let clonedNotes = _.clone(materialNotes);
    clonedNotes[index] = value;
    setMaterialNotes(clonedNotes);
  };

  const handleRemoveMaterialNotes = (index) => {
    let clonedNotes = _.clone(materialNotes);
    clonedNotes.splice(index, 1);
    setMaterialNotes(clonedNotes);
  };

  return (
    <StyledDrawer
      title={currentStep === 0 ? t("check_cart_data") : t("enter_data")}
      placement="right"
      onClose={closeDrawer}
      open={visible}
      width={isMobile ? "100vw" : 500}
      extra={
        currentStep === 0 ? (
          <EmptyCartButton onClick={() => dispatch(deleteAllFromCart())} />
        ) : (
          currentStep === 1 && <BackButton onClick={() => setCurrentStep(0)} />
        )
      }
    >
      {currentStep === 0 && (
        <>
          <DrawerTotalPrice>
            <h4>{t("total")}</h4>
            <p>{CURRENCY + formatPrice(cartItemsTotal)}</p>
          </DrawerTotalPrice>
          <DrawerCartItems>
            {cartData.map((item, index) => {
              const totalPrice = item.price * item.quantity;
              return (
                <div key={index}>
                  <CartItem>
                    {/*<img*/}
                    {/*  src={*/}
                    {/*    !!item.image*/}
                    {/*      ? item.image*/}
                    {/*      : PRODUCT_PLACEHOLDER_LOCAL_SRC*/}
                    {/*  }*/}
                    {/*  alt={item.displayname}*/}
                    {/*/>*/}
                    <p style={{ marginBottom: 0, fontWeight: 600 }}>
                      {item.displayname}
                    </p>
                    {!!item.additionalInfo &&
                      item.additionalInfo.length > 0 &&
                      item.additionalInfo?.map((item, index) => (
                        <p
                          key={index}
                          style={{
                            marginBottom: 0,
                            fontSize: 12,
                            lineHeight: 1.5,
                            color: "#888",
                          }}
                        >
                          {item.key + ": " + item.value}
                        </p>
                      ))}
                    <div className={"d-flex justify-content-between mb-3"}>
                      <div className={"d-flex"}>
                        {!item.alwaysIncluded ? (
                          <>
                            <QuantityChooser>
                              <button
                                onClick={() =>
                                  handleDecreaseCartQuantity(item, index)
                                }
                              >
                                <MinusOutlined />
                              </button>
                              <p>{item.quantity}</p>
                              <button onClick={() => handleAddToCart(item)}>
                                <PlusOutlined />
                              </button>
                            </QuantityChooser>
                            <RemoveItemButton
                              onClick={() =>
                                handleRemoveItemFromCart(item, index)
                              }
                            />
                          </>
                        ) : (
                          <AlwaysIncluded>
                            <span>{t("always_included")}</span>
                          </AlwaysIncluded>
                        )}
                      </div>
                      <p style={{ fontWeight: 600, alignSelf: "end" }}>
                        {CURRENCY + formatPrice(totalPrice)}
                      </p>
                    </div>
                    {!item.alwaysIncluded && (
                      <Input
                        placeholder={t("placeholder_material_notes")}
                        onChange={(e) =>
                          handleSetMaterialNotes(e.target.value, index)
                        }
                        value={materialNotes[index]}
                        allowClear
                        onBlur={(e) =>
                          handleCommentChange(e.target.value, index)
                        }
                      />
                    )}
                  </CartItem>
                  <Divider />
                </div>
              );
            })}
            {cartData.length > 0 ? (
              <ProceedButton type={"primary"} onClick={onClickProceed}>
                {t("next_step")}
              </ProceedButton>
            ) : (
              <EmptyCart>{t("cart_empty")}</EmptyCart>
            )}
          </DrawerCartItems>
        </>
      )}
      {currentStep === 1 && (
        <Form
          form={requestForm}
          layout="vertical"
          style={{ padding: 20 }}
          initialValues={{ techSupportNeeded: true, techSupportHours: 3 }}
        >
          <p style={{ whiteSpace: "pre-line" }}></p>
          <Row gutter={16}>
            <Col lg={24}>
              <Form.Item
                name="title"
                label={t("request_title")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_title"),
                  },
                ]}
              >
                <Input placeholder={t("request_title")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="space" label={t("request_space")}>
                {!!company &&
                !!company.locations &&
                company.locations.length > 0 ? (
                  <Select
                    placeholder={t("pick_location")}
                    options={company.locations.map((item) => {
                      return { value: item, label: item };
                    })}
                  />
                ) : (
                  <Input placeholder={t("request_space")} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="attendees"
                label={t("attendees")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_attendees"),
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <InputNumber
                  placeholder={t("attendees")}
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col lg={24}>
              <Form.Item
                name="date"
                label={t("request_date")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_date"),
                  },
                ]}
              >
                <RangePicker
                  showTime
                  format="DD-MM-YYYY HH:mm"
                  placeholder={[t("start"), t("end")]}
                  minuteStep={15}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                  inputReadOnly
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="techSupportNeeded"
                label={t("technical_support_needed")}
                rules={[
                  {
                    required: true,
                    message: t("form.pick_value"),
                  },
                ]}
              >
                <Select>
                  <Option value={true}>{t("yes")}</Option>
                  <Option value={false}>{t("no")}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="notes" label={t("notes_label")}>
                <Input.TextArea maxLength={10000} />
              </Form.Item>
            </Col>
          </Row>
          <ProceedButton
            type="primary"
            onClick={onClickProceed}
            loading={submitLoading}
          >
            {t("submit_request")}
          </ProceedButton>
        </Form>
      )}
    </StyledDrawer>
  );
};

const AlwaysIncluded = styled.div`
  span {
    color: grey;
  }
`;

const RemoveItemButton = styled(CloseOutlined)`
  cursor: pointer;
  padding-left: 8px;
  color: grey;
`;

const EmptyCartButton = styled(DeleteOutlined)`
  cursor: pointer;
  color: grey;
`;

const BackButton = styled(ArrowLeftOutlined)`
  cursor: pointer;
  color: grey;
`;

const EmptyCart = styled.h4`
  font-size: 20px;
  text-align: center;
  margin-top: 2rem;
`;

const ProceedButton = styled(Button)`
  width: 100%;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const DrawerCartItems = styled.div`
  padding: 50px 20px 0;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const DrawerTotalPrice = styled.div`
  z-index: 99;
  min-width: 0;
  display: flex;
  width: fill-available;
  padding: 10px 18px;
  background-color: rgb(247, 247, 247);
  position: fixed;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
  }

  p {
    font-weight: 600;
  }
`;

const QuantityChooser = styled.div`
  display: flex;
  cursor: pointer;
  background-color: rgb(41, 41, 41);
  transition: all 0.2s ease;
  padding: 4px 0;
  margin-top: 6px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: rgb(0 0 0 / 16%) 0px 6px 12px;

  p {
    color: #fff;
    margin: 0 12px !important;
    align-self: center;
  }

  button {
    background-color: rgb(41, 41, 41);
    color: #fff;
    border: none;
    font-size: 18px;
    border-radius: 100%;
  }
`;

const CartItem = styled.div`
  margin-bottom: 1rem;

  img {
    width: fill-available;
    max-width: 200px;
  }
`;

export default CartDrawer;
