export const REQUEST_ID_PREFIX = "SD-";
export const REQUEST_STATUS_SENT = "sent";
export const REQUEST_STATUS_OPEN = "open";
export const REQUEST_STATUS_OPTION = "option";
export const REQUEST_STATUS_CONFIRMED = "confirmed";
export const REQUEST_STATUS_DELETED = "deleted";
export const REQUEST_STATUS_DECLINED = "declined";
export const REQUEST_STATUS_CANCELED = "canceled";

export const REQUEST_VERSION_CURRENT = "CURRENT";
