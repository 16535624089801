import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  active_requests,
  dashboard,
  finished_requests,
  materials,
} from "../urls";
import DashboardLayout from "../layouts/DashboardLayout";
import NavMotion from "../layouts/NavMotion";

// dashboard routing
const Home = lazy(() => import("../pages/dashboard/Home"));
const ActiveRequests = lazy(() => import("../pages/dashboard/ActiveRequests"));
const FinishedRequests = lazy(() =>
  import("../pages/dashboard/FinishedRequests")
);
const ShopList = lazy(() => import("../pages/dashboard/ShopList"));

const DashboardRoutes = () => {
  const location = useLocation();
  return (
    <Route path={[dashboard, active_requests, materials, finished_requests]}>
      <DashboardLayout>
        <Switch location={location} key={location.pathname}>
          <NavMotion>
            <Route path={dashboard} component={Home} />
            <Route path={active_requests} component={ActiveRequests} />
            <Route path={finished_requests} component={FinishedRequests} />
            <Route path={materials} component={ShopList} />
          </NavMotion>
        </Switch>
      </DashboardLayout>
    </Route>
  );
};

export default DashboardRoutes;
